import React from "react";

import { Link } from "react-router-dom";
// Contact Icons

// App Store
// import apple_store from '../../../assets/images/app_store/apple_store.svg'
// import play_store from '../../../assets/images/app_store/play_store.svg'

// // Social Icons
// import facebook from '../../../assets/images/icon/social_icons/facebook.svg';
// import twitter from '../../../assets/images/icon/social_icons/twitter.svg'
// import linkedin from '../../../assets/images/icon/social_icons/linkedin.svg'
// import instagram from '../../../assets/images/icon/social_icons/insta.svg'
// import youtube from '../../../assets/images/icon/social_icons/youtube.svg'
// import telegram from '../../../assets/images/icon/social_icons/telegram.svg'

// Divider
import divider from "../../../assets/images/miscellanous/footer_divider.svg";

// Data
import { data } from "../../Data";

const companyLinks = [
  { text: "Privacy Policy", link: "privacy-policy" },
  { text: "Terms of Service", link: "term-of-service" },
  // {'text':'Terms & Conditions','link':'#'},
  // {'text':'Contact Us','link':'#'},
];

// const quickLinks = [
//   {'text':'Performance','link':'#'},
//   {'text':'Blog','link':'#'},
//   {'text':'Elite Club Conclave','link':'#'},
//   {'text':'Magazine','link':'#'},
//   {'text':'Media and PR','link':'#'},
//   {'text':'Video Gallery','link':'#'},
//   {'text':'Newsletters','link':'#'},
//   {'text':'Stock Screener','link':'#'},
//   {'text':'IPOs','link':'#'},
//   {'text':'Financial Calculators','link':'#'},
// ]

// const serviceLinks = [
//   {'text':'Equentis - Research & Ranking','link':'#'},
//   {'text':'Equentis - Private Wealth','link':'#'},
//   {'text':'Equentis - Informed InvestoRR','link':'#'},
//   {'text':'Equentis - MultiplyRR','link':'#'},
// ]

// const socialMedia = [
//   {'text':'Facebook','link':'#' ,icon:facebook},
//   {'text':'X','link':'#',icon:twitter},
//   {'text':'Instagram','link':'#',icon:instagram},
//   {'text':'Linkedin','link':'#',icon:linkedin},
//   {'text':'Youtube','link':'#',icon:youtube},
//   {'text':'Telegram','link':'#',icon:telegram},
// ]

// const appLink = [
//   {'text':'Apple Store','link':data.apple_store,'icon':apple_store},
//   {'text':'Google Play Store','link':data.google_play_store,'icon':play_store},
// ]

const contactInformation = [
  <li
    key="contactno"
    className="flex items-center space-x-2 text-sm font-manrope"
  >
    <img src={`${data.ec2}icon/ic_contactno.svg`} alt="contactno" />
    <strong>+91 22 61013888</strong>
  </li>,
  <li
    key="support"
    className="flex items-center space-x-2 md:text-sm text-[12.5px] font-manrope"
  >
    <img src={`${data.ec2}icon/ic_support.svg`} alt="support" />{" "}
    <strong>
      <a
        href="mailto:support.informedinvestorr@equentis.com"
        className="text-blue-400 hover:underline"
      >
        support.informedinvestorr@equentisedutech.com
      </a>
    </strong>
  </li>,
  <li
    key="email"
    className="flex items-start space-x-2 text-sm text-[#A8A8A8] font-pillGothic"
  >
    <img src={`${data.ec2}icon/ic_emailus.svg`} alt="email" />
    <span>
      <strong className="text-white font-manrope">
        Equentis Edutech Limited
      </strong>
      <br />
      A-703, Marathon Futurex, 7th Floor,Mafatlal
      <br />
      Mills Compound, Delisle Road, Mumbai
      <br />
      400013, Maharashtra, India.
    </span>
  </li>,
];

// const address = {
//   "SEBI Registered Investment Advisor ":<p>
//       <strong>Registered name:</strong>  Equentis Wealth Advisory Services  Limited, <strong>SEBI Registration No.:</strong> INA000003874 <strong>Type of registration:</strong> Non-Individual <strong>Validity:</strong> Dec 08, 2015  Perpetual <strong>BASL Membership</strong>,
//       <strong>No:</strong> 1816 <strong>CIN:</strong> U74999MH2015PLC262812 <strong>Registered office address:</strong> Marathon Futurex, A-603 6th Floor, Mafatlal Mills Compound,N. M. Joshi Marg, Lower Parel, Mumbai 400013. <strong>Telephone</strong> ,
//       <strong>No.:</strong> +91 22 61013800 <strong>Principal Officer:</strong>Mr. Manish Goel, <strong>Email:</strong> po@equentis.com. <strong>Telephone No.:</strong> +91 22 61013800 <strong>Compliance officer:</strong>Mr. Rakesh Gupta, <strong>mail:</strong> compliance@equentis.com, ,
//       <strong>Telephone No.:</strong>+91 22 61013800 <strong>Grievance officer:</strong> Mr. Swapnil Patil, <strong>Email:</strong> grievance@researchandranking.com, <strong>Telephone No.:</strong> +91 22 61013800 <strong>SCORES:</strong> https://www.scores.gov.in/
//       </p>
//     ,
//   "SEBI Office":<p><strong>Address:</strong> Plot No.C4-A, 'G' Block Bandra-Kurla Complex, Bandra (East), Mumbai - 400051, Maharashtra <strong>Tel:</strong> +91-22-26449000 / 40459000 | <strong>Fax:</strong> +91-22-26449019-22 / 40459019-22<br/>
//    <strong>Email:</strong> sebi@sebi.gov.in <strong>Toll Free Investor Helpline:</strong> 1800 22 7575</p>

//   }

// const stockInfo = <p><strong>Financial Calculators:</strong> SIP Calculator, CAGR Calculator, Retirement Planning Calculator <strong>IPOs:</strong> Current IPOs, Upcoming IPOs, Listed IPO ,
// <strong>Most Visited On Research and Ranking : Nifty 50 Stocks :</strong>Technologies,Punjab className='underline'> Adani Ports and SEZ, Asian Paints, Bajaj Finserv Ltd, Bharti Airtel, ICICI Bank Share Price, Tata Steel Ltd, Mahindra & Mahindra, Kotak ,
// Mahindra, Reliance Industries Limited, Titan Company, Tata Motors, UltraTech Cement, Wipro, Hero MotoCorp, Shree Cement, Nestle India, Maruti Suzuki, HCL Technologies, ITC Limited, Bharti,
// Airtel,<strong>Trending Stocks :</strong>Adani Green,Tata Power,Central Dep. Service,Yes Bank,IRCTC,Adani Wilmar,Tata Steel,Visa steel,Oil India,BPCL,Affle India,Adani Power,Happiest Minds ,Technologies,Punjab & Sind National Bank,Canara Bank,CDSL,Yes Bank,Trident,Urja Global,SAIL</p>

const FooterSection1 = () => {
  return (
    <>
      <div className="bg-footer-primary text-white">
        <div className="w-full flex flex-col  items-start justify-start px-2  py-3 | md:flex-row md:ml-2  md:px-10 md:py-3">
          {/* Column 1: Contact Information */}
          <div className="w-3/4  mb-2 ml-3 items-start | md:mb-0  md:w-2/5 ">
            <img
              src={`${data.ec2}equentis_footer_logo.svg`}
              alt="Equentis Logo"
              className="mb-5 xl:mb-8 w-60 h-18  object-contain"
            />
            <ul className="space-y-3 md:space-y-6 pt-3 text-sm md:text-2xl">
              {contactInformation.map((e) => e)}
            </ul>
          </div>
          {/* Column 2: Company Links */}
          <div className="w-full flex flex-row flex-wrap py-3 ml-5 items-start | md:w-1/2  lg:ml-2 px-5 md:py-8 lg:px-16">
            <h1 className="text-[14px] lg:text-xl    font-pillGothic text-[#A8A8A8]">
              <strong className="font-manrope font-[700] text-white">
                Corporate Identification Number (CIN):
              </strong>{" "}
              U85490MH2024PLC432104
            </h1>
            <div className="w-1/2  |  md:w-full">
              {/* <h4 className="font-bold text-[14px] py-4 | md:text-xl font-manrope md:ml-0 ">
                Company
              </h4> */}
              <ul className="space-y-3  text-sm mt-1 md:mt-8 text-[#A8A8A8] py-4 | md:space-y-1 md:text-2xl font-pillGothic">
                {companyLinks.map((e, index) => (
                  <li
                    key={`company_link_${index}`}
                    className="text-[16px] font-pillGothic  hover:text-white hover:cursor-pointer"
                  >
                    {/* <a href={e.link} target="_blank">
                      {e.text}
                    </a> */}
                    <Link to={e.link}>{e.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <img src={divider} alt="divider" className="flex mx-auto w-full" />
    </>
  );
};

export default FooterSection1;
