import React, { useEffect } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import { Navigation } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import "./Testimonal.scss";

import girl1 from "./../../../../../assets/images/test_avatar/girl1.png";

import girl2 from "./../../../../../assets/images/test_avatar/girl2.png";
import girl3 from "./../../../../../assets/images/test_avatar/girl3.png";

import boy1 from "./../../../../../assets/images/test_avatar/boy1.png";
import boy2 from "./../../../../../assets/images/test_avatar/boy2.png";
import boy3 from "./../../../../../assets/images/test_avatar/boy3.png";

import Testimonial from "./Testimonal";

const testimonials = [
  {
    text: "My experience with Informed InvestoRR has been fantastic. The combination of engaging courses and expert insights has helped me improve my investment skills.",
    name: "Rohit Sharma",
    title: "Digital Marketer",
    image: boy1,
  },
  {
    text: "I use the Informed InvestoRR app to stay updated on the latest stock market news, and their daily analysis is incredibly effective and insightful.",
    name: "Arjun Nair",
    title: "Senior Product Manager",
    image: boy2,
  },
  {
    text: "Informed InvestoRR’s expert webinars and active community has greatly supported my investing journey.",
    name: "Rashmi Halemani",
    title: "Retail Manager",
    image: girl1,
  },
  {
    text: "The platform has given me insights I wouldn't have found anywhere else. My knowledge and confidence have increased tenfold.",
    name: "Priya Singh",
    title: "Financial Analyst",
    image: girl2,
  },
  {
    text: "Informed InvestoRR’s features have revolutionized the way I approach investments.",
    name: "Vikram Joshi",
    title: "Software Engineer",
    image: boy3,
  },
  {
    text: "The combination of live webinars and real-time analysis has helped me tremendously.",
    name: "Sonal Kapoor",
    title: "Digital Marketer",
    image: girl3,
  },
];

const TestimonialSlider = () => {
  const [slides, setSlides] = React.useState(3);
  const setSlidesPerview = () => {
    setSlides(
      window.innerWidth <= 550
        ? 1
        : window.innerWidth <= 720
        ? 2
        : window.innerWidth > 720
        ? 3
        : 0
    );
  };

  useEffect(() => {
    setSlidesPerview();
    window.addEventListener("resize", setSlidesPerview);
    return () => {
      window.removeEventListener("resize", setSlidesPerview);
    };
  }, []);

  return (
    <div className="mx-5 md:mx-40 flex flex-col justify-center py-6 md:py-8">
      <div className="text-center mb-5">
        <h1 className="text-[22px] md:text-[40px]    mb-4 font-manrope">
          Success{" "}
          <span className="text-secondary-color font-[700]">Stories</span>
        </h1>
        <p className="text-[15px] md:text-[25px] text-[#5B5B5B] font-pillGothic">
          Why Informed InvestoRR users love the app
        </p>
      </div>

      <div className="w-full  md:w-full xl:w-11/12 h-full xl:h-64  mx-auto  flex justify-center mt-2 md:mt-10">
        {/* Swiper Slider */}
        <Swiper
          modules={[Navigation]}
          navigation={true}
          direction={"horizontal"}
          centeredSlides={false}
          loop={false}
          spaceBetween={20}
          slidesPerView={slides}
        >
          {testimonials.map((testimonial, idx) => (
            <SwiperSlide key={idx}>
              <Testimonial key={idx} testimonial={testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default TestimonialSlider;
